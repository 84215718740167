import React, { useEffect, useState } from 'react'
import { GetDocs } from '../../../firebase/utils'

const Customers = () => {

    const [Customers,setCustomers]=useState(null)
  
    useEffect(() => {
      GetDocs("Customers").then((result)=>setCustomers(result)) 
    }, [])
    
  return (
    
    <div className='FadeIn' style={{display:'flex',flexWrap:'wrap',gap:'2rem',alignContent:'flex-start'}}>
      {Customers===null? "...Loading" : Customers.map((Customer,id)=>{ return <div key={id}>{Customer.Nom}
    </div>})}
    </div>
  )
}

export default Customers