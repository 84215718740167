import React from 'react'
import './AddProduct.css'
import Field from '../../../components/Field/Field'
import Pill from '../../../components/Pill/Pill'
import Form from '../../../components/Form/Form'

import PlaceHolderFile from '../../../Assets/PlaceHolderFile.svg'



const AddProduct = () => {
  return (
    <div id='Main' className='FadeIn'>
        <div id='CurrentActivity'>
                                        
                        <div className='heading'>
                            Ajouter un Produit
                        </div>
                        <div className='container formulaire'>

                            <Form/>
                        
                        </div>
                        
        </div>
        <div id='RightBar' >
                    <div className='container'>
                        <span>Ajoutez une bannière Pub</span>
                        
                        <div id='FileUploader'>
                            <label id='labelDropMoi' htmlFor='dropMoi'>
                            <div className='box ratio169'><img src={PlaceHolderFile} alt="PlaceHolderFile ico" />{/*<div className='plus'></div>*/}</div>
                            </label>
                            <input type="file" name="fichier" id="dropMoi" multiple onChange={e=>console.log(e.currentTarget.files)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
                        </div>
                        <div>
                            <div>Format Fichier</div>
                            <span>
                                L  = 361px   /  H = 462 px
                            </span>
                        </div>
                        <Pill bgcolor={'#e8c133'} color={'#000'} text={'Ajouter bannière'}/>

                    </div>
                    <div className='container'>
                    <span>Ajoutez le produit à la une</span>
                        <div id='FileUploader'>
                            <label id='labelDropMoi' htmlFor='dropMoi'>
                            <div className='box ratio11'><img src={PlaceHolderFile} alt="PlaceHolderFile ico" />{/*<div className='plus'></div>*/}</div>
                            </label>
                            <input type="file" name="fichier" id="dropMoi" multiple onChange={e=>console.log(e.currentTarget.files)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
                        </div>
                        <div>
                            <div>Format Fichier</div>
                            <span>
                                L  = 361px   /  H = 462 px
                            </span>
                        </div>
                        <div className='PrixProd'>
                            <Field typeInput={'number'} FieldName={'Prix du produit'} className={'smallInput'}/>
                        </div>
                        <Pill bgcolor={'#e8c133'} color={'#000'} text={'Ajouter produit à la une'}/>

                    </div>
        </div>
    </div>
  )
}

export default AddProduct