import React, { useState } from 'react'
import Product from '../../../components/Product/Product'
import { GetDocs } from '../../../firebase/utils'
import { useEffect } from 'react'

const Products = () => {

  const [Produits,setProduits]=useState(null)

  useEffect(() => {
    GetDocs("Produits").then((result)=>setProduits(result)) 
  
  }, [])
  

  return (
    <div className='FadeIn' style={{display:'flex',flexWrap:'wrap',gap:'2rem',alignContent:'flex-start'}}>
      {Produits===null? "...Loading" : Produits.map((produit,id)=>{return (<Product key={id} prodDetails={produit}/>)})}
    </div>
  )
}

export default Products