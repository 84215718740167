import React, { useState } from 'react'
import './ColorPicker.css'


var Colors=['#fff','#000','#f00','#0f0','#00f','#4f5','#39d','#0f0','#00f','#4f5']
var Colors2=[
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF"
]
var Colors3 =['MediumVioletRed', 'DeepPink', 'PaleVioletRed', 'HotPink', 
    'LightPink', 'Pink', 'DarkRed', 'Red', 'Firebrick', 'Crimson', 
    'IndianRed', 'LightCoral', 'Salmon', 'DarkSalmon', 
    'LightSalmon', 'OrangeRed', 'Tomato', 'DarkOrange', 'Coral', 
    'Orange', 'DarkKhaki', 'Gold', 'Khaki', 'PeachPuff', 'Yellow', 
    'PaleGoldenrod', 'Moccasin', 'PapayaWhip', 'LightGoldenrodYellow', 
    'LemonChiffon', 'LightYellow','Maroon', 'Brown', 'SaddleBrown', 'Sienna', 
    'Chocolate', 'DarkGoldenrod', 'Peru', 'RosyBrown', 'Goldenrod', 
    'SandyBrown', 'Tan', 'Burlywood', 'Wheat', 'NavajoWhite', 'Bisque', 
    'BlanchedAlmond', 'Cornsilk','DarkGreen', 'Green', 'DarkOliveGreen', 
    'ForestGreen', 'SeaGreen', 'Olive', 'OliveDrab', 'MediumSeaGreen', 
    'LimeGreen', 'Lime', 'SpringGreen', 'MediumSpringGreen', 'DarkSeaGreen', 
    'MediumAquamarine', 'YellowGreen', 'LawnGreen', 'Chartreuse', 'LightGreen', 
    'GreenYellow', 'PaleGreen', 'Teal', 'DarkCyan', 'LightSeaGreen', 
    'CadelBlue', 'DarkTurquoise', 'MediumTurquoise', 'Turquoise', 'Aqua', 
    'Cyan', 'AquaMarine', 'PaleTurquoise', 'LightCyan', 'Navy', 'DarkBlue', 
    'MediumBlue', 'Blue', 'MidnightBlue', 'RoyalBlue', 'SteelBlue', 
    'DodgerBlue', 'DeepSkyBlue', 'CornFlowerBlue', 'SkyBlue', 'LightSkyBlue', 
    'LightSteelBlue', 'LightBlue', 'PowderBlue', 'Indigo', 'Purple', 
    'DarkMagenta', 'DarkViolet', 'DarkSlateBlue', 'BlueViolet', 'DarkOrchid', 
    'Fuchsia', 'Magenta', 'SlateBlue', 'MediumSlateBlue', 
    'MediumOrchid', 'MediumPurple', 'Orchid', 'Violet', 'Plum', 
    'Thistle', 'Lavender', 'MistyRose', 'AntiqueWhite', 'Linen', 
    'Beige', 'WhiteSmoke', 'LavenderBlush', 'OldLace', 'AliceBlue', 
    'Seashell', 'GhostWhite', 'Honeydew', 'ForalWhite', 'Azure', 
    'MintCream', 'Snow', 'Ivory', 'White', 'Black', 'DarkSlateGray', 
    'DimGray', 'SlateGrey', 'Gray', 'LightSlateGray', 'DarkGray', 
    'Silver', 'LightGray', 'Gainsboro']

const ColorPicker = () => {
    const [currentColor,setcurrentColor]=useState(Colors3[Math.round(Math.random().toFixed(3)*10)+Math.round(Math.random().toFixed(3))])

    const OpenPalette=(e)=>{
        e.currentTarget.nextSibling.classList.toggle('Close')
        

    }


    const SelectColor=(e)=>{
        setcurrentColor(e.currentTarget.style.backgroundColor)

        e.currentTarget.parentNode.parentNode.classList.toggle('Close')

    }

  return (
    <div className='ColorPicker'>

        <span className='currentColor' style={{backgroundColor:currentColor,transition:'all ease 0.3s'}} onClick={e=>OpenPalette(e)}></span>

        <div className='container_Palette Close'>
            <div className='palette'>
                {Colors2.map((Color,index)=>{ return(<span style={{backgroundColor:Color}} key={index} onClick={e=>SelectColor(e)}></span>)})}
            </div>
        </div>

        
    </div>
  )
}

export default ColorPicker