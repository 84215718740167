
import { addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db,auth,storage } from "./firebase";
import { createUserWithEmailAndPassword, deleteUser, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "firebase/auth";


/**Gestion des Comptes utilisateurs */
export function CreateAccount(email,password) {
    createUserWithEmailAndPassword(auth,email,password)
    
}
export function SignIn (email,password) {
    signInWithEmailAndPassword(email,password)
}
export function SignOut() {
    signOut(auth)
    
}


export function AddUser(collectionName,idDoc) {

    
}
export async function getUser(collectionName,idDoc) {

    
    const DocRef=doc(db,collectionName,idDoc)
    try {
        const docSnap = await getDoc(DocRef);
        if(docSnap.exists()) {
            //setProduit(docSnap.data());
            //retourne la valeur du doc ici
        } else {
            console.log("Document n'existe pas ")
        }
    
    } catch(error) {
        console.log(error)
    }
   
    
}
export function DeleteUser(user) {
    deleteUser(user)
     
}
export function UpdateUserEmail(user,newEmail) {
    const current_user=user
    updateEmail(current_user,newEmail)
     
}
export function UpdateUserPassword(user,newPassword) {
    updatePassword(user,newPassword)
    
}





/**Gestion des produits,users,etc...*/

export async function AddDoc(Doc) {

    const ProduitsDb=collection(db,"Produits")

    addDoc(ProduitsDb,Doc)
    
}

export function DeleteDoc (DocRef) {

}
export function UpdateDoc(DocRef) {
    updateDoc(DocRef)
    
}

export async function GetProduct(Product_collection,id) {
    const DocRef=doc(db,Product_collection,id)

    try {
        const DocSnap=await getDoc(DocRef)
        if (DocSnap.exists()) {

            return DocSnap.data()
            
        }
        else {
            console.log("Produit n'existe pas")
        }

    } catch (error) {

        console.log(error)
        
    }

    
}

export async function GetDocs(collectionName) {
    const CollectionProd=collection(db,collectionName)

    const Data=await getDocs(CollectionProd);
    const Docs=Data.docs.map((doc)=>({...doc.data(),id:doc.id}))
    return Docs

    
}


/*
function CRUD() {
  const [newName, setNewName] = useState("");
  const [newMarks, setNewMarks] = useState(0);

  const [users, setUsers] = useState([]);

 
  const usersCollectionRef = collection(db, "users");


  const UtilisateursCollect = collection(db, "Utilisateurs");

  const createUser = async () => {
    await addDoc(usersCollectionRef, { name: newName, marks: Number(newMarks) });
    getUsers();
  };

  const updateUser = async (id, marks) => {
    const userDoc = doc(db, "users", id);
    const newFields = { marks: marks + 1 };
    await updateDoc(userDoc, newFields);
    getUsers();
  };

  const deleteUser = async (id) => {
    const userDoc = doc(db, "users", id);
    await deleteDoc(userDoc);
    getUsers();
  };

  const getUsers = async () => {
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };



}
*/

