import React, {useContext, useEffect, useRef, useState } from 'react'
import './Form.css'
import Field from '../Field/Field'
import Pill from '../Pill/Pill'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import Notif from '../Notif/Notif'

import PlaceHolderFile from '../../Assets/PlaceHolderFile.svg'

import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage } from '../../firebase/firebase'
import { v4 } from 'uuid'

import { DashboardContext } from '../../Hooks/DashboardContext'
import { AddDoc } from '../../firebase/utils'

import ColorPicker from '../ColorPicker/ColorPicker'
import SelectOption from '../SelectOption/SelectOption'


const DeleteButton = ({onClick}) => {

  return (
    <div className="delete hide" onClick={onClick}>X</div>
  )
}





const Form = () => {

  const Options1=['Electronique','Vêtements','Beauté']
  const Options2=['Homme','Femme','Garçon','Fille']
  const Options3=['XS','S',"M",'L','XL','XXL']


  const {setloading}=useContext(DashboardContext)
  const CategorieInput=useRef()


  const [Nom,setNom]=useState('')
  const [SKU,setSKU]=useState('')
  const [Categorie,setCategorie]=useState('')
  const [Categories,setCategories]=useState([])
  const [Description, setDescription] = useState('')
  const [EtatDuProduit, setEtatduProduit] = useState('')
  const [Quantite, setQuantite] = useState('')
  const [PrixVendeur, setPrix] = useState('')
  const [PrixInApp, setPrixInApp] = useState('')
  const [TauxDeMarge, setTauxDeMarge] = useState('')
 
  const [Urls,setUrls]=useState(null)

  const [notif,setnotif]=useState(false)
  const [ErrorMessage,setErrorMessage]=useState()

  const [ajoutable,setajoutable]=useState(false)
  const [files,setFiles] = useState(null);

  const [ImgVignette,setImgVignette]=useState(null)
  const [Imgs,setImgs]=useState(null)
  const [prod,setProd]=useState(null)

  const [srcImg,setsrcImg]=useState(null)

  const Produit={
    SKU:SKU,
    Nom:Nom,
    Categorie : Categorie ,
    Categories: Categories,
    Description :Description,
    UrlsImages:Urls,
    EtatDuProduit :EtatDuProduit,
    Quantite :Quantite,
    PrixVendeur :PrixVendeur,
    PrixInApp:PrixInApp,
    TauxDeMarge :TauxDeMarge,
    createdAt: serverTimestamp(),

  }
  

 function BatchUpload (e) {

    const Files=e.currentTarget.files
    const DeleteButtons =e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll('.delete')

    setImgs(Files)
    for (let index = 0; index < Files.length; index++) {
      DeleteButtons[index].classList.remove('hide')
     
    }
    
  
  }
  function UpdateImgs (index,e) {

  const File=e.currentTarget.files

  e.currentTarget.parentNode.querySelector('.delete').classList.remove('hide')

 
  if (Imgs===null) {

    const TabImgs=Array(4).fill(undefined)
    const UpdatedImgsSingle= TabImgs.map((img,i)=>{
      if(i===index) {
        
        return img=File[0];
        ;
      }
      else {
        return img
      }
    })
    setImgs(UpdatedImgsSingle)
  
  }
  else {

  const UpdatedImgs =[...Imgs].map((img,i) => {
    if (i===index) {

      return img=File[0];

      } else {

     return img;
    }
    });

    
    
    setImgs(UpdatedImgs);

  }
  
  }
  function DeletePicture(e,index) {
    const DeleteButton=e.currentTarget
    DeleteButton.classList.add('hide')

    DeleteButton.parentNode.getElementsByTagName('input')[0].value=''


    if (Imgs===null) return
    else {
  
     const DeletedTab=[...Imgs].map((img,idx)=>{
        if (idx===index) {
          return img=undefined
        }
        else {
          return img
        }
        })

      setImgs(DeletedTab)
    }
   
  }
  function UploadVignette (e) {
    const valeur=e.currentTarget.files[0]
    const DeleteButton=e.currentTarget.parentNode.parentNode.parentNode.querySelector('.delete')
    DeleteButton.classList.remove('hide')
    setImgVignette(valeur)

   
  }
  function DeleteVignette (e) {
    const DeleteButton=e.currentTarget

    DeleteButton.classList.add('hide')

    DeleteButton.parentNode.parentNode.querySelector('#Uploader').value=''
    setImgVignette(null)

    
  }



  function Assigner(e,valideWidth,valideHeight){
    const Img=e.currentTarget.files[0]

    const image=new Image();

    image.src=URL.createObjectURL(Img);

    if (image==null) return
     
    image.onload=()=>{

      if (image.width===valideWidth && image.height===valideHeight) {
        console.log('La hauteur de cette image est : '+image.height+'px','La largeur de cette image est : '+image.width+'px')

        //setsrcImg(image.src)
        //console.log('Bon Format')
        
        
      } 
      else {

        console.log('Mauvais Format l\'image')
        document.querySelector('#ErrorDial').classList.remove('hide')
        document.querySelector('#ErrorDial').showModal()
        
      }
      
    }

    

  }

  const AddProdDetail=async (key,valeur)=>{
  
    setProd((prevstate)=>({...prevstate,[key]:valeur}))
  }

 
  const sender = async ()=>{
    console.log('before',Produit)
    await addDoc(collection(db, "Produits"), Produit)
   
    
  }

  const ResetForm=()=>{
    setErrorMessage("Produit bien ajouté a la base de donnée")
    setnotif(true)
          setNom('')
          setCategorie('')
          setCategories([])
          setDescription('')
          setEtatduProduit('')
          setQuantite('')
          setPrix('')
          setPrixInApp('')
          setTauxDeMarge('')
          setFiles(null)
          setUrls(null) 


  }

  const AjouterUnProduit= async ()=>{
      const readytosend= async(urls)=>{
        setUrls(urls)

        /** Desactivation du Loading quand la requete est traitée  */
        setloading(false)
      }

   
      const uploadFile =() => {
            
       if(files==null) return
        Promise.all(
          [...files].map(img => {
            return new Promise((resolu)=>{
              const imageRef = ref(storage, `Produits/${Nom}/${Nom + v4()}`);

              uploadBytes(imageRef, img).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                  resolu(url)})
                }
              )

              /**Loading en attendant que la requete soit traitée  */
              setloading(true)

            })
              }
          )
        ).then((toutlesresolution)=>readytosend(toutlesresolution)).catch(console.log('en attente de l\'upload des images',[...files]))
      
      }

     
      
      uploadFile()
        /*
        if (files == null) return;
        [...files].forEach(img => {
          
          const imageRef = ref(storage, `Produits/${Nom}/${Nom + v4()}`);
              uploadBytes(imageRef, img).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                  console.log('generer a chaque tour',url)
                  setUrls([...Urls,url])
                //setUrls((prev) => [...prev, url])
                //console.log(Urls)
                
              
          
                });
              });
        })
        }
      */
    
    
      
          //await addDoc(collection(db, "Produits"), Produit)
          
  }

  const verificationVide=(e)=>{

        const valeur=e.currentTarget.value

        const Regex_InputText= new RegExp(/^[a-zA-Z]{3,5}.*$/)
        const Regex_InputNumber= new RegExp(/^[0-9]{2,10}.*$/)
 
       
       /* Test de text */ 
       if (e.currentTarget.type==='text') {
        
       
       if(!Regex_InputText.test(valeur)) 
        {
          //console.log("Le champ "+ e.currentTarget.previousSibling.innerText + " doit etre rempli")
          e.currentTarget.classList.add('invalide')
          setajoutable(false)
        }
        else {
          //console.log(Regex_Input.test(e.currentTarget.value))
          e.currentTarget.classList.remove('invalide')
          setajoutable(true)
        }

      }
        /* Test de nombre */ 
      if (e.currentTarget.type==='number') {
        if(!Regex_InputNumber.test(valeur)) 
          {
            //console.log("Le champ "+ e.currentTarget.previousSibling.innerText + " doit etre rempli")
            e.currentTarget.classList.add('invalide')
            setajoutable(false)
          }
          else {
            //console.log(Regex_Input.test(e.currentTarget.value))
            e.currentTarget.classList.remove('invalide')
            setajoutable(true)
          }
        }   
      
      
  }

  /**verification Validite des images et bon formats 
  useEffect(
    ()=>{
  
      if (ImgVignette===null) return
      Assigner(ImgVignette,168,142)
    },[ImgVignette]
  )

  useEffect(
    ()=>{
  
      if (Imgs===null) return
      Assigner(Imgs[0],390,462)
    },[Imgs]


  )
*/
  useEffect(() => {
    if (Urls==null) return
    //console.log('la variable urls avant envoi du produit est a ',Urls)
    sender()
    //console.log('la variable urls apres envoi du produit est a ',Urls)
    ResetForm() ;
    (()=>{console.log('after',Produit)})();
   
  }, [Urls])


  return (
    <>

    
      <dialog id='ErrorDial' className='hide'>
        {'Mauvaise Taille de fichier'}
        <Pill bgcolor={'#e8c133'} bordercolor={'transparent'} classe={'BtnDialog'} color={'#000'} onclick={e=>{e.currentTarget.parentNode.close();e.currentTarget.parentNode.classList.add('hide')}} text={'Fermer'}/>
      </dialog>
    

      <Field FieldName={'Nom du produit'} onchange={e=>setNom(e.currentTarget.value)} value={Nom} onblur={e=>AddProdDetail(['Nom'],e.currentTarget.value).then(console.log(prod))} className={'normalInput'}/>

      <div id='text_descr'>
            <label htmlFor="textarea"  >Description</label>
            <textarea name="" id="textarea" onChange={e=>setDescription(e.currentTarget.value)} value={Description} onBlur={e=>AddProdDetail(['Description'],e.currentTarget.value).then(console.log(prod))} placeholder='Description de produit'></textarea>
      </div>

      <div className='flexH'>
          <Field typeInput='number' FieldName={'Prix du Produit'} onchange={e=>setEtatduProduit(e.currentTarget.value)} value={EtatDuProduit} onblur={e=>AddProdDetail(['Prix'],e.currentTarget.value).then(console.log(prod))} className={'smallInput'}/>

          <Field typeInput='number' FieldName={'Prix promotionnel'} onchange={e=>setQuantite(e.currentTarget.value)} value={Quantite} onblur={e=>AddProdDetail(['Promo'],e.currentTarget.value).then(console.log(prod))} className={'smallInput'}/>

          <Field typeInput='number'  FieldName={'Taux de reduction'} onchange={e=>setTauxDeMarge(e.currentTarget.value)} value={TauxDeMarge} onblur={e=>AddProdDetail(['Reduction'],e.currentTarget.value).then(console.log(prod))} className={'smallInput'}/>

          <Field typeInput='number'  FieldName={'Stock'} onchange={e=>setPrixInApp(e.currentTarget.value)} value={PrixInApp} onblur={e=>AddProdDetail(['Stock'],e.currentTarget.value).then(console.log(prod))} className={'smallInput'}/>

          <Field FieldName={'Identifiant unique'} onchange={e=>setSKU(e.currentTarget.value)} value={SKU} onblur={e=>AddProdDetail(['SKU'],e.currentTarget.value).then(console.log(prod))} className={'smallInput Capitalize'}/>
          
      </div>  
      <div className='flexH'>

          <SelectOption Label={'Selectionner la categorie :'} options={Options1}/>
          <SelectOption Label={'Genre :'} options={Options2}/>
          <SelectOption Label={'Taille :'} options={Options3}/>
        
      </div>  


      <div>Couleurs</div>

      <div className='flexH'>
          
          <ColorPicker/>
          <ColorPicker/>
          <ColorPicker/>
          <ColorPicker/>

      </div> 

      <div>

        <span style={{marginBottom:'1rem',fontWeight:'300',display:'block'}}>Images de produit</span>
       
        <div id='ZoneUploadeur'>

          <div id='ImageVignette'>
            <span>Image Vignette</span>

            <div className='vign'>

              
                <div>
                <DeleteButton onClick={e=>DeleteVignette(e)}/>
                  <label id='ImgVignette' htmlFor='Uploader' vignette='true'>
                    <div>
                      <img src={ImgVignette===null?PlaceHolderFile:URL.createObjectURL(ImgVignette)} alt={ImgVignette===null?'':ImgVignette.name}/>
                    </div>
                  </label>
                </div>
              
              <div style={{display:'flex',flexDirection:'column',gap:'1rem'}}>
                <div id='UploaderDiv'>
                  <input type="file" name="" id="Uploader" onChange={e=>UploadVignette(e)} accept='image/png, image/jpeg'/>
                </div>

                <div style={{display:'flex',flexDirection:'column'}}>
                  <span>
                    Format fichier :  L  = 168 px  H = 142 px
                  </span>
                  <span>
                    type de fichier :   .png ou .jpg
                  </span>
                </div>
              </div>

            </div>

          </div>


          <div id='Images'>

            <span>
              Image(s)  produit
            </span>
        
            <div id='RosterImg' className='flexH'>

              <div className='ImgsRoster'>
              <DeleteButton onClick={e=>DeletePicture(e,0)}/>
                <label htmlFor="Img0">
                  <div id='Img0div'><img src={Imgs===null||Imgs[0]===undefined?PlaceHolderFile:URL.createObjectURL(Imgs[0])} alt={Imgs===null||Imgs[0]===undefined?'':Imgs[0].name}/></div>
                </label>
                <input type="file" name="" id="Img0"  onChange={e=>UpdateImgs(0,e)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
              </div>
            
              <div className='ImgsRoster'>
              <DeleteButton onClick={e=>DeletePicture(e,1)}/>
                <label htmlFor="Img1" >
                  <div id='Img1div'><img src={Imgs===null||Imgs[1]===undefined?PlaceHolderFile:URL.createObjectURL(Imgs[1])} alt={Imgs===null||Imgs[1]===undefined?'':Imgs[1].name}/></div>
                </label>
                <input type="file" name="" id="Img1"  onChange={e=>UpdateImgs(1,e)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
              </div>
          
              <div className='ImgsRoster'>
              <DeleteButton onClick={e=>DeletePicture(e,2)}/>
                <label htmlFor="Img2" >
                  <div id='Img2div'><img src={Imgs===null||Imgs[2]===undefined?PlaceHolderFile:URL.createObjectURL(Imgs[2])} alt={Imgs===null||Imgs[2]===undefined?'':Imgs[2].name}/></div>
                </label>
                <input type="file" name="" id="Img2"  onChange={e=>UpdateImgs(2,e)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
              </div>
          
              <div className='ImgsRoster'>
              <DeleteButton onClick={e=>DeletePicture(e,3)}/>
                <label htmlFor="img3" >
                  <div id='img3div'><img src={Imgs===null||Imgs[3]===undefined?PlaceHolderFile:URL.createObjectURL(Imgs[3])} alt={Imgs===null||Imgs[3]===undefined?'':Imgs[3].name}/></div>
                </label>
                <input type="file" name="" id="img3" onChange={e=>UpdateImgs(3,e)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
              </div>

            </div>
          
            <div style={{display:'flex',gap:'1rem'}}>

              <div id='UploaderDiv2'>
                <label htmlFor="Uploader2" >Choisir les fichiers</label>
                <input type="file" name="ImagesUplodeur" id="Uploader2" multiple onChange={e=>BatchUpload(e)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif' />
              </div>

              <div style={{display:'flex',flexDirection:'column'}}>

                <span>
                  Format fichier :  L  = 390 px H = 462 px
                </span>
                <span>
                  type de fichier :   .png ou .jpg
                </span>

              </div>

            </div>

          </div>

        </div>

      </div> 



      <div id="Action">
                        { !ajoutable && <Pill text={"Ajoutez un produit"} color={"#000"} bgcolor={"#E8C133"} bordercolor={"transparent"} classe={"btn"} onclick={e=>{AddDoc(prod).then((result)=>{console.log('Bien Rajouter')})}}/>}
                        <Pill text={"Voir les autres produits"} color={"#fff"} bgcolor={"transparent"} bordercolor={"#e8c133"} classe={"btn"}/>
      </div>

      {notif?<Notif Message={ErrorMessage} onclick={e=>setnotif(false)}/>:''}

    </>
  )

  
}



export default Form