import React, { useEffect } from 'react'
import './SelectOption.css'
import { useState } from 'react'

const SelectOption = ({Label,options}) => {

    const [select,setSelect]=useState('--Select an option--')
    const [hide,setHide]=useState('hide')

    const Select=(e)=>{

        setSelect(e.currentTarget.innerText)
        e.currentTarget.parentNode.previousSibling.classList.add('current')

        if (hide==='') {
            setHide('hide')
        }
        else {
            setHide('')
        }

    }

    const Choose=(e)=>{

        e.currentTarget.nextSibling.classList.toggle('hide')
        

        if (hide==='') {
            setHide('hide')
        }
        else {
            setHide('')
        }

    }

   /*useEffect(() => {
        var Elements=document.getElementsByClassName('options')
       console.log(Elements[2])
   }, [select])
   */
    

  return (
    <div className='SelectOption'>
        <span>{Label}</span>
        <div className={'selected'} onClick={e=>Choose(e)}><span>{select}</span></div>
        <div className={'options '+hide}>
            {options.map((option,index)=>{return(<div onClick={e=>Select(e)} key={index}> {option} </div>)})}
            
        </div>
    </div>
  )
}

export default SelectOption