import React from 'react'
import './Product.css'
import { NavLink, useNavigate } from 'react-router-dom'



const Product = ({prodDetails}) => {

  const navigateTo=useNavigate() 


  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',background:'#d9d9d9',width:'10rem',height:'20rem'}} onClick={e=>{navigateTo('../AddProduct')}} idproduct={prodDetails.id}>
        
        <div style={{background:'#b3b3b3',display:'flex'}}>
            <img src={prodDetails.UrlsImages[0]===null?" ":prodDetails.UrlsImages[0]} alt="" width={'100%'} />
        </div>
        <div>
            <span>{prodDetails.Nom}</span>
            <span style={{color:'red'}}>{prodDetails.PrixInApp}</span>
            <span>{prodDetails.Categorie}</span>
            <span style={{color:'red'}}>{prodDetails.Rates}</span>
        </div>
    </div>
  )
}

export default Product