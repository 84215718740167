import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes,Route } from 'react-router-dom';
import Connexion from './Pages/Connexion/Connexion';
import Dashboard from './Pages/Dashboard/Dashboard';
import DashboardProvider from './Hooks/DashboardContext';
import AddProduct from './Pages/Dashboard/AddProduct/AddProduct';
import Home from './Pages/Dashboard/Home/Home';
import Products from './Pages/Dashboard/Products/Products';
import Purchases from './Pages/Dashboard/Purchases/Purchases';
import Customers from './Pages/Dashboard/Customers/Customers';
import Analytics from './Pages/Dashboard/Analytics/Analytics';
import Settings from './Pages/Dashboard/Settings/Settings';


function App() {


 

  return (
    <DashboardProvider>
      <div className="App">
        
        <Router>

          <Routes>

            <Route path='*' element={<Connexion/>}/>
            <Route path='/Connexion' element={<Connexion/>}/>

            <Route path='/Dashboard' element={<Dashboard/>}>

              <Route path='/Dashboard' element={<div className='FadeIn'>Base</div>}/>

              <Route path='Home' element={<Home/>}/> 
              <Route path='Products' element={<Products/>}/>                     
              <Route path='Purchases' element={<Purchases/>}/>                     
              <Route path='Customers' element={<Customers/>}/>                         
              <Route path='Analytics' element={<Analytics/>}/>                         
              <Route path='Settings' element={<Settings/>}/> 

              <Route path='AddProduct' element={<AddProduct/>}/> 

            </Route>
            
          </Routes>
      
        </Router>
      
      </div>
    </DashboardProvider>
  );
}

export default App;
