import React, { useContext, useEffect } from 'react'
import './Dashboard.css'

import logoDash from '../../Assets/logo_ornoir_dashboard.svg'
import loupe from '../../Assets/Loupe.svg'
import Profil from '../../Assets/Profil/Avatar.png'

import ProfilParam from '../../Assets/ProfilParam.svg'
import disconnected from '../../Assets/Disconnected.svg'

import Home from '../../Assets/Home.svg'
import Products from '../../Assets/Product.svg'
import Customer from '../../Assets/Customers.svg'
import Purchase from '../../Assets/Purchase.svg'
import Analytics from '../../Assets/Analytics.svg'
import Settings from '../../Assets/Settings.svg'


import { onAuthStateChanged ,signOut} from 'firebase/auth'
import {auth} from '../../firebase/firebase'

import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import Loading from '../../components/Loading/Loading'
import { DashboardContext } from '../../Hooks/DashboardContext'
import { useRef } from 'react'
import { useState } from 'react'
import Pill from '../../components/Pill/Pill'

//import { collection, getDocs, getDoc} from 'firebase/firestore'



const Dashboard = () => {

    const linkActive={
        color: '#e8c133',
         fontWeight: 700,
         transition: '0.3s',
         scale:'1.2',
         filter:'grayscale(0)'
        }

    const [ProfilMenu,setProfilMenu]=useState(false)
    const {loading}=useContext(DashboardContext)
    const DashboardElmnt=useRef()

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //const uid = user.uid;
              // ...a decommenter plus tard
              //console.log("uid", uid)
            } else {
              // User is signed out
              // ... a decommenter plus tard
              //console.log("user is logged out")
            }
          });

         /* getDocs(collection(db,"Produits"))
                .then((querySnapshot)=>{              
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id:doc.id }));

                    console.log(querySnapshot)
                    console.log(newData)
                    //setTodos(newData);                
                    //console.log(todos, newData);
                })
        */

    }, [])


    useEffect(() => {
        
        DashboardElmnt.current.classList.toggle('disable')

    }, [loading])
    
    const navigate = useNavigate();

    const handleLogout = () => {    
        alert('Out you are now')           
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/");
            //console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }

    const MenuProfilHandle=()=>{
        if(ProfilMenu) {
            setProfilMenu(false)
        }
        else (
            setProfilMenu(true)
        )
    }
  
    

  return (

    <>
        <div id='Progress'>
            {loading && <Loading/>}
        </div>

        <main id='Dashboard' ref={DashboardElmnt}>

            

            <nav id="navbar">
                <div id='leftside'>
                    <div id='logoDash' onClick={e=>navigate('/Dashboard')}>
                        <img src={logoDash} alt="Logo Dashboard" />
                    </div>

                    <div id='Search' >
                        <label htmlFor="Search_input">
                            <img src={loupe} alt="icone de recherche" />
                        </label>
                        <input id='Search_input' type="text"  placeholder='Rechercher un produit'/>
                    </div>
                </div>
                    
                <div id="rightside">
                
                    <Pill bgcolor={'#e8c133'} color={'#000'} text={'Ajouter le produit'} onclick={e=>navigate('AddProduct')}/>
                    <div className='div'>
                        <span>Bonjour,</span><span> {'Deo'}</span>

                        <div className='div'>
                            <img src={Profil} alt="ProfilPic" onClick={MenuProfilHandle} />

                            {ProfilMenu && <div id="MenuProfil">

                                <div className='opt'>
                                    <div><img src={ProfilParam} alt="Parametre du profil" /></div>
                                    <div>Paramètres</div>
                                </div>

                                <div className='opt' onClick={handleLogout}>
                                    <div><img src={disconnected} alt="Deconnexion" /></div>
                                    <div>Déconnexion</div>
                                </div>
                        
                            </div>}
                       
                        </div>

                        
                    </div>
                    
                </div>
                
            </nav>
            
        
            <section>
               
                <div id='SideBar' className='container'>
                    <ul>
                        <li><NavLink to={'Home'} style={({isActive})=>{return isActive?linkActive:{}}}><img src={Home} alt="Home" /> Accueil </NavLink></li>

                        <li><NavLink to={'Products'} style={({isActive})=>{return isActive?linkActive:{}}}> <img src={Products} alt="Products" /> Produits</NavLink></li>

                        <li><NavLink to={'Purchases'} style={({isActive})=>{return isActive?linkActive:{}}}> <img src={Purchase} alt="Purchases" /> Commandes</NavLink></li>

                        <li><NavLink to={'Customers'} style={({isActive})=>{return isActive?linkActive:{}}}> <img src={Customer} alt="Customers" /> Clientèles</NavLink></li>

                        <li><NavLink to={'Analytics'} style={({isActive})=>{return isActive?linkActive:{}}}> <img src={Analytics} alt="Analytics" /> Analytiques</NavLink></li>

                       
                    </ul>
                    <ul>
                        <li><NavLink to={'Settings'} style={({isActive})=>{return isActive?linkActive:{}}}> <img src={Settings} alt="Settings" /> Paramètres</NavLink></li>
                    </ul>
                </div>

                
                <Outlet/>
                
            </section>
            

        </main>
    </>
  )
}

export default Dashboard